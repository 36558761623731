import {Link} from 'react-router-dom';
import img1 from '../../office-water-cooler-206x300.png';
import img2 from '../../5-gallon.png1_.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTasks, faClock, faFile, faUsers, faBell, faEnvelopeOpenText, faTint, faMoneyBillAlt, faFastForward, faMapMarkerAlt, faPhone, faBars, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../IMG-20240813-WA0009.png';
import { SocialIcon } from 'react-social-icons';
const Contact = () => {

	const close = () => {
	    const sec1 = document.getElementById('sec1');
	    sec1.style.top = '-150%';
	}

	const show = () => {
	    const sec1 = document.getElementById('sec1');
	    sec1.style.top = '0%';
	}

	return(
		<>
			<section className="sec1" id="sec1">
		        <div className="row">
		            <div className="col-md-5">
		                <div className="close-bg">
		                  <FontAwesomeIcon icon={faTimesCircle} className="close" onClick={() => close()} />
		                </div>
		                <p>
		                  <img src = {img2} className="no-mobile" /><br /><br />
		                  {/*<h1>
		                    <FontAwesomeIcon icon={faMapMarkerAlt} />
		                    172 Chatsworth Main Road Umhlathuzana
		                  </h1>*/}
		                  <h4>
		                    {/*<FontAwesomeIcon icon={faBuilding} />
		                    8 stage water purification process*/}
		                  </h4>
		                </p>
		            </div>
		            <div className="col-md-7">
		                <ul>
		                  <li><Link to="/">Home</Link></li>
		                  <li><Link to="/info">Info</Link></li>
		                  <li><Link to="/videos">Videos</Link></li>
		                  <li><Link to="/contact">Contact</Link></li>
		                </ul>
		            </div>
		        </div>
		    </section>
			<section className="sec2 mt-5">
		        <div className="container">
		          <FontAwesomeIcon icon={faBars} className="menu-bars" onClick={() => show()} />
		          <h1 className="display-1 mt-5">Contact Us</h1><br /><br />
		          <div className="row">
		            <div className="col-md-8">
		                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3458.448348126407!2d30.918764874256222!3d-29.908992169718882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ef7ab1e61945677%3A0x865ec8441441edbc!2s172%20Chatsworth%20Main%20Rd%2C%20Umhlatuzana%2C%20Chatsworth%2C%204092!5e0!3m2!1sen!2sza!4v1725365253068!5m2!1sen!2sza" style={{border:0, width: '100%', height: '400px'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe><br /><br />
		            </div>
		            <div className="col-md-4">
		            	<div className="contact-content">
			                <h3>Address</h3>
			                <p><FontAwesomeIcon icon={faMapMarkerAlt} className="icon-top" /> 172 Chatsworth Main Road Umhlathuzana 3 Karrico Center</p>
			                <h3>Email Us</h3>
			                <p><FontAwesomeIcon icon={faEnvelope} className="icon-top" /> shop@aqua4u.co.za</p>
			                <h3>Call Us</h3>
			                <p><FontAwesomeIcon icon={faPhone} className="icon-top" /> 063 597 6436</p>
		                </div>
		            </div>
		            {/*<div className="col-md-8">
		                <div className="text-start">
		                  <span className="btn btn-info w-100 nmt">
		                    See More
		                  </span>
		                </div>
		            </div>*/}
		          </div>
		        </div>
		    </section>
		    <footer className="footer bg-dark">
		        <div className="container">
		          <div className="footer-top-sec">
		            <div className="row">
		              <div className="col-md-10">
		                <img src={Logo} className="App-logo-footer" alt="logo" />
		              </div>

		              <div className="col-md-2 mt-5">
		                <SocialIcon url="https://www.instagram.com" bgColor="#333" />&#160;
		                <SocialIcon url="https://www.tiktok.com" bgColor="#333" />&#160;
		                <SocialIcon url="https://m.facebook.com" bgColor="#333" />
		              </div>
		            </div>
		          </div>


		          <div className="footer-bottom-sec">
		            <div className="row">
		              <div className="col-md-8">
		                <h3>About Aqua4U</h3>
		                <p className="footer-about">Welcome to Aqua4U, We are a water shop located in <b>172 Chatsworth Main Road Umhlathuzana</b> <b>3 Karrico Center.</b></p>
		              </div>
		              <div className="col-md-4">

		                <div className="footer-contact">
		                  <div className="row">
		                    <div className="col-md-1">
		                      <FontAwesomeIcon icon={faMapMarkerAlt} className="icon-top" />
		                    </div>
		                    <div className="col-md-11">
		                      <p>172 Chatsworth Main Road Umhlathuzana 3 Karrico Center</p>
		                    </div>
		                  </div>
		                </div>

		                <div className="footer-contact">
		                  <div className="row">
		                    <div className="col-md-1">
		                      <FontAwesomeIcon icon={faPhone} className="icon-top" />
		                    </div>
		                    <div className="col-md-11">
		                      <p>063 597 6436</p>
		                    </div>
		                  </div>
		                </div>

		                <div className="footer-contact">
		                  <div className="row">
		                    <div className="col-md-1">
		                      <FontAwesomeIcon icon={faEnvelope} className="icon-top" />
		                    </div>
		                    <div className="col-md-11">
		                      <p>shop@aqua4u.co.za</p>
		                    </div>
		                  </div>
		                </div>

		              </div>
		            </div>
		          </div>
		        </div>
		      </footer>
		</>
	);
}

export default Contact;
import { useState } from 'react';
import {Link} from 'react-router-dom';
import img1 from '../../office-water-cooler-206x300.png';
import img2 from '../../5-gallon.png1_.png';
import img3 from '../../IMG-20240903-WA0007.jpg';
import img4 from '../../IMG-20240903-WA0006.jpg';
import Logo from '../../IMG-20240813-WA0009.png';
import Logo2 from '../../AQUA 4U logo-01.png';
import Logo3 from '../../AQUA 4U logo white-01.png'
import Video from '../../VID-20240901-WA0003.mp4';
import { SocialIcon } from 'react-social-icons';
import { Player } from 'video-react';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import {SlideshowLightbox} from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';
import '../../App.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTasks, faClock, faFile, faUsers, faBell, faEnvelopeOpenText, faTint, faMoneyBillAlt, faFastForward, faMapMarkerAlt, faPhone, faBars, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'video-react/dist/video-react.css';

function App() {
  const [stage, setStage] = useState(1);
  const [stage2, setStage2] = useState('done');
  const { width, height } = useWindowSize();

  const close = () => {
      const sec1 = document.getElementById('sec1');
      sec1.style.top = '-150%';
  }

  const show = () => {
      const sec1 = document.getElementById('sec1');
      sec1.style.top = '0%';
  }

  const close2 = () => {
      const close2 = document.getElementById('close2');
      const nav = document.getElementById('nav-container');
      close2.style.display = "none";
      nav.style.right = '-23rem';
  }

  const show2 = () => {
      const close2 = document.getElementById('close2');
      const sec1 = document.getElementById('nav-container');
      close2.style.display = "block";
      sec1.style.right = '0%';
  }

  return (
    <>
      {
        stage2!='done'?
        <>
          <Confetti
            width={width}
            height={height}
          />
        <div className="modal-bg">
            {
              stage==1?
                <div className="my-modal">
                  <h1 className="text-primary">Welcome</h1><br />
                  <h3>Aqua4U Water Shop Grand Opening: A New Era of Purity</h3>
                  <p>Today marks a new beginning for our community as we celebrate the grand opening of Aqua4U, your new destination for the highest-quality water — 99% purified and refreshingly clean. At Aqua4U, we believe that every person deserves access to water that is safe, healthy, and enjoyable. That’s why we have developed a comprehensive purification system that removes 99% of all impurities, ensuring our customers receive water that is crystal clear, delicious, and beneficial for their well-being.</p>
                  <p className="no-mobile">In a world where water quality is increasingly threatened by pollution and contaminants, Aqua4U aims to be a beacon of purity. Our advanced purification process uses the latest technology to eliminate harmful bacteria, chemicals, and particles while preserving the natural minerals that give water its essential health benefits. The result is water that not only tastes great but is also perfectly suited for everyday hydration, cooking, and even for your pets and plants.</p>
                  <span className="btn btn-info text-light" onClick={()=>setStage(2)}>Next</span>
                </div>
                :
                (stage==2?
                  <div className="my-modal2">
                    <Player muted>
                      <source src={Video} />
                    </Player><br />
                    <span className="btn btn-info text-light" onClick={()=>setStage2('done')}>Close</span>
                  </div>
                  :''
                )
            }
        </div>
        </>:''
      }
      <header className="header">
        <div className="hero-gloss">
          {/*<p className="text-logo-top"><img src = {Logo3} className = "logo" /></p>*/}
          <FontAwesomeIcon icon={faBars} className="menu-bars" onClick={() => show2()} />

          {/*<h1 className="text-logo">Aqua4U<span className="spot">.</span></h1>*/}

          <div className = "dsc" >
            <div className="row">
              <div className="col-md-7">
                <img src={Logo3} className = "logo2" />
              </div>
              <div className="col-md-5">
                <div>
                  {/*<h1 className = "my-display-1">Aqua4u: A New Era of Purity</h1><br />*/}
                  <p className = "my-display-1">In a world where water quality is increasingly threatened by pollution and contaminants, Aqua4U aims to be a beacon of purity. Our advanced purification process uses the latest technology to eliminate harmful bacteria, chemicals, and particles while preserving the natural minerals that give water its essential health benefits. The result is water that not only tastes great but is also perfectly suited for everyday hydration, cooking, and even for your pets and plants.</p><br />
                </div>
              </div>
            </div>
          </div>

        </div>
      </header>

      <section className="sec1" id="sec1">
        <div className="row">
            <div className="col-md-5">
                <div className="close-bg">
                  <FontAwesomeIcon icon={faTimesCircle} className="close" onClick={() => close()} />
                </div>
                <p>
                  <img src = {img2} className="no-mobile" /><br /><br />
                  {/*<h1>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    172 Chatsworth Main Road Umhlathuzana
                  </h1>*/}
                  <h4>
                    {/*<FontAwesomeIcon icon={faBuilding} />
                    8 stage water purification process*/}
                  </h4>
                </p>
            </div>
            <div className="col-md-7">
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/info">Info</Link></li>
                  <li><Link to="/videos">Videos</Link></li>
                  <li><Link to="/contact">Contact</Link></li>
                </ul>
            </div>
        </div>
      </section>

      <div className="nav-container" id="nav-container">
        <div className="row">
          <div className="col-2">
              <FontAwesomeIcon icon={faTimesCircle} className="close2" id="close2" onClick={() => close2()} />
          </div>
          <div className="col-10">
            <nav className="nav">
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/info">Info</Link></li>
                <li><Link to="/videos">Videos</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <section className="sec2">
        <div className="container">
          <h1 className="display-1">Some Info</h1>
          <div className="row">
            <div className="col-md-8">
                <ul className="text-start">
                  <li className="lead fs-3 mb-4">On demand purified water and refill stations</li>
                  <li className="lead fs-3 mb-4">8 stage water purification process</li>
                  <li className="lead fs-3 mb-4">Open during load shedding and water outages</li>
                </ul>
            </div>
            <div className="col-md-4">
                <img src = {img1} />
            </div>
          </div>
        </div>
      </section>
      <section className="sec2">
        <div className="container">
          <h1 className="display-1 mb-4">Grand Opening</h1><br />
          <div className="row">
            <div className="col-md-6 mb-4">
              <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
                <img src = {img3} className="flyer" />
              </SlideshowLightbox>
            </div>
            <div className="col-md-6 mb-4">
              <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
                <img src = {img4} className="flyer" />
              </SlideshowLightbox>
            </div><br /><br /><br />
          </div>
        </div>
      </section>
      <footer className="footer bg-dark">
        <div className="container">
          <div className="footer-top-sec">
            <div className="row">
              <div className="col-md-10">
                <img src={Logo} className="App-logo-footer" alt="logo" />
              </div>

              <div className="col-md-2 mt-5">
                <SocialIcon url="https://www.instagram.com" bgColor="#333" />&#160;
                <SocialIcon url="https://www.tiktok.com" bgColor="#333" />&#160;
                <SocialIcon url="https://m.facebook.com" bgColor="#333" />
              </div>
            </div>
          </div>


          <div className="footer-bottom-sec">
            <div className="row">
              <div className="col-md-8">
                <h3>About Aqua4U</h3>
                <p className="footer-about">Welcome to Aqua4U, We are a water shop located in <b>172 Chatsworth Main Road Umhlathuzana</b> <b>3 Karrico Center.</b></p>
              </div>
              <div className="col-md-4">

                <div className="footer-contact">
                  <div className="row">
                    <div className="col-md-1">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="icon-top" />
                    </div>
                    <div className="col-md-11">
                      <p>172 Chatsworth Main Road Umhlathuzana 3 Karrico Center</p>
                    </div>
                  </div>
                </div>

                <div className="footer-contact">
                  <div className="row">
                    <div className="col-md-1">
                      <FontAwesomeIcon icon={faPhone} className="icon-top" />
                    </div>
                    <div className="col-md-11">
                      <p>063 597 6436</p>
                    </div>
                  </div>
                </div>

                <div className="footer-contact">
                  <div className="row">
                    <div className="col-md-1">
                      <FontAwesomeIcon icon={faEnvelope} className="icon-top" />
                    </div>
                    <div className="col-md-11">
                      <p>shop@aqua4u.co.za</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>
      </footer>
    </>
  );
}

export default App;

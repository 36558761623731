import Video1 from '../../VID-20240830-WA0005.mp4';
import Video2 from '../../VID-20240830-WA0006.mp4';
import Video3 from '../../VID-20240910-WA0009.mp4';
import { SocialIcon } from 'react-social-icons';
import { Player } from 'video-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTasks, faClock, faFile, faUsers, faBell, faEnvelopeOpenText, faTint, faMoneyBillAlt, faFastForward, faMapMarkerAlt, faPhone, faBars, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import img1 from '../../office-water-cooler-206x300.png';
import img2 from '../../5-gallon.png1_.png';
import Logo from '../../IMG-20240813-WA0009.png';
const Videos = () => {

	const close = () => {
	    const sec1 = document.getElementById('sec1');
	    sec1.style.top = '-150%';
	}

	const show = () => {
	    const sec1 = document.getElementById('sec1');
	    sec1.style.top = '0%';
	}

	return(
		<>
			<section className="sec1" id="sec1">
		        <div className="row">
		            <div className="col-md-5">
		                <div className="close-bg">
		                  <FontAwesomeIcon icon={faTimesCircle} className="close" onClick={() => close()} />
		                </div>
		                <p>
		                  <img src = {img2} className="no-mobile" /><br /><br />
		                  {/*<h1>
		                    <FontAwesomeIcon icon={faMapMarkerAlt} />
		                    172 Chatsworth Main Road Umhlathuzana
		                  </h1>*/}
		                  <h4>
		                    {/*<FontAwesomeIcon icon={faBuilding} />
		                    8 stage water purification process*/}
		                  </h4>
		                </p>
		            </div>
		            <div className="col-md-7">
		                <ul>
		                  <li><Link to="/">Home</Link></li>
		                  <li><Link to="/info">Info</Link></li>
		                  <li><Link to="/videos">Videos</Link></li>
		                  <li><Link to="/contact">Contact</Link></li>
		                </ul>
		            </div>
		        </div>
		    </section>
			<section className="sec2 mt-5">
		        <div className="container">
		        <FontAwesomeIcon icon={faBars} className="menu-bars" onClick={() => show()} />
		          <h1 className="display-1">Some Videos</h1><br /><br />
		          <div className="row">
		            <div className="col-md-6">
		              <div style={{ marginBottom: '2em' }}>
		                <Player muted>
		                  <source src={Video1} />
		                </Player>
		              </div>
		            </div>
		            <div className="col-md-6">
		              <div style={{ marginBottom: '2em' }}>
		                <Player muted>
		                  <source src={Video2} />
		                </Player>
		              </div>
		            </div>
		            <div className="col-md-6">
		              <div style={{ marginBottom: '2em' }}>
		                <Player muted>
		                  <source src={Video3} />
		                </Player>
		              </div>
		            </div>
		          </div>
		        </div>
		    </section>
		    <footer className="footer bg-dark">
		        <div className="container">
		          <div className="footer-top-sec">
		            <div className="row">
		              <div className="col-md-10">
		                <img src={Logo} className="App-logo-footer" alt="logo" />
		              </div>

		              <div className="col-md-2 mt-5">
		                <SocialIcon url="https://www.instagram.com" bgColor="#333" />&#160;
		                <SocialIcon url="https://www.tiktok.com" bgColor="#333" />&#160;
		                <SocialIcon url="https://m.facebook.com" bgColor="#333" />
		              </div>
		            </div>
		          </div>


		          <div className="footer-bottom-sec">
		            <div className="row">
		              <div className="col-md-8">
		                <h3>About Aqua4U</h3>
		                <p className="footer-about">Welcome to Aqua4U, We are a water shop located in <b>172 Chatsworth Main Road Umhlathuzana</b> <b>3 Karrico Center.</b></p>
		              </div>
		              <div className="col-md-4">

		                <div className="footer-contact">
		                  <div className="row">
		                    <div className="col-md-1">
		                      <FontAwesomeIcon icon={faMapMarkerAlt} className="icon-top" />
		                    </div>
		                    <div className="col-md-11">
		                      <p>172 Chatsworth Main Road Umhlathuzana 3 Karrico Center</p>
		                    </div>
		                  </div>
		                </div>

		                <div className="footer-contact">
		                  <div className="row">
		                    <div className="col-md-1">
		                      <FontAwesomeIcon icon={faPhone} className="icon-top" />
		                    </div>
		                    <div className="col-md-11">
		                      <p>063 597 6436</p>
		                    </div>
		                  </div>
		                </div>

		                <div className="footer-contact">
		                  <div className="row">
		                    <div className="col-md-1">
		                      <FontAwesomeIcon icon={faEnvelope} className="icon-top" />
		                    </div>
		                    <div className="col-md-11">
		                      <p>shop@aqua4u.co.za</p>
		                    </div>
		                  </div>
		                </div>

		              </div>
		            </div>
		          </div>
		        </div>
		      </footer>
	    </>
	);

}

export default Videos;
import {createBrowserRouter, createRoutesFromElements, Route, Outlet, RouterProvider} from 'react-router-dom';
import Home from './Components/Pages/Home';
import Info from './Components/Pages/info';
import Videos from './Components/Pages/Videos';
import Contact from './Components/Pages/Contact';
import Root from './Components/Pages/Root';
function App() {

  const router = createBrowserRouter(
      createRoutesFromElements(
          <Route path="/" element={<Root />} >
              <Route index element={<Home />} />
              <Route path="/info" element={<Info />} />
              <Route path="/videos" element={<Videos />} />
              <Route path="/products" element={<Videos />} />
              <Route path="/contact" element={<Contact />} />
          </Route>
      )
  );

  return (
        <>
          <RouterProvider router={router} />
        </>
  );
}

export default App;
